var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout, Dependencies } from "~/core/decorator";
import { Emit } from "vue-property-decorator";
import { State } from "vuex-class";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import ImportCaseCommon from "~/components/case-manage/import-case-common.vue";
import { UploadFileService } from "~/services/common-service/upload-file.service";
import { ImportExcelCaseService } from "~/services/dataimp-service/import-excel-case.service";
import { ImportOthersDataExcelRecordService } from "~/services/dataimp-service/import-others-data-excel-record.service";
var AdjustCityImportList = /** @class */ (function (_super) {
    __extends(AdjustCityImportList, _super);
    function AdjustCityImportList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // 导入类型
        _this.templateType = "IMPORT_CHANGE_CITY";
        _this.loading = {
            state: false
        };
        _this.searchModel = {
            principalName: "",
            importDataExcelStatus: "",
            templateType: _this.templateType //导入类型
        };
        /**弹窗 */
        _this.dialog = {
            importCase: false,
            importCaseDetail: false
        };
        _this.dataSet = [];
        _this.batchNumber = "";
        return _this;
    }
    AdjustCityImportList.prototype.mounted = function () {
        var _this = this;
        this.refreshData();
        // 收到消息通知时更新未读消息
        this.$reminder.addMessageListener().subscribe(function (data) {
            // 城市调整导入[IMPORT_CHANGE_CITY] 城市调整确认[IMPORT_CHANGE_CONFIRMED]
            if (data.messageType === "IMPORT_CHANGE_CITY" ||
                data.messageType === "IMPORT_CHANGE_CONFIRMED") {
                _this.refreshData();
            }
        });
    };
    AdjustCityImportList.prototype.refreshData = function () {
        var _this = this;
        this.loading.state = true;
        this.importOthersDataExcelRecordService
            .getImportOthersDataExcelList(this.searchModel, this.pageService, this.sortService, this.loading)
            .subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    /**
     * 案件导入
     */
    AdjustCityImportList.prototype.caseImportDialogOpen = function () {
        this.dialog.importCase = true;
    };
    /**
     * 错误信息下载
     */
    AdjustCityImportList.prototype.errorTipsDownload = function (row) {
        var _this = this;
        this.uploadFileService
            .getFileStreamById(row.resultUrl)
            .subscribe(function () { return _this.$message.success("下载成功"); });
    };
    /**
     * 取消导入
     */
    AdjustCityImportList.prototype.cancelImport = function (id) {
        var _this = this;
        this.$confirm("您确定取消此案件吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        }).then(function () {
            _this.importOthersDataExcelRecordService
                .cancelOthersExcelImpCase(id)
                .subscribe(function (data) {
                _this.$message.success("取消导入成功!"), _this.refreshData();
            });
        });
    };
    /**
     * 确认导入
     */
    AdjustCityImportList.prototype.confirmImport = function (id) {
        var _this = this;
        this.$confirm("您确定要导入此案件吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        }).then(function () {
            _this.importOthersDataExcelRecordService
                .confirmOthersExcelImpCase(id)
                .subscribe(function (data) {
                _this.$message.success("导入成功!"), _this.refreshData();
            });
        });
    };
    /**
     * 查看详情
     */
    AdjustCityImportList.prototype.viewDetail = function (rowData) { };
    /**
     * 下载模板
     */
    AdjustCityImportList.prototype.downLoadTemplate = function () {
        var _this = this;
        this.importExcelCaseService
            .getSystemTemplate({ templateType: this.templateType })
            .subscribe(function (data) {
            if (data)
                _this.uploadFileService
                    .getFileStreamById(data.fileId)
                    .subscribe(function () { return _this.$message.success("下载成功"); });
        });
    };
    __decorate([
        Dependencies(PageService)
    ], AdjustCityImportList.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], AdjustCityImportList.prototype, "sortService", void 0);
    __decorate([
        Dependencies(UploadFileService)
    ], AdjustCityImportList.prototype, "uploadFileService", void 0);
    __decorate([
        Dependencies(ImportExcelCaseService)
    ], AdjustCityImportList.prototype, "importExcelCaseService", void 0);
    __decorate([
        Dependencies(ImportOthersDataExcelRecordService)
    ], AdjustCityImportList.prototype, "importOthersDataExcelRecordService", void 0);
    __decorate([
        State
    ], AdjustCityImportList.prototype, "principalList", void 0);
    __decorate([
        Emit("onClickCaseDetail")
    ], AdjustCityImportList.prototype, "viewDetail", null);
    AdjustCityImportList = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                ImportCaseCommon: ImportCaseCommon
            }
        })
    ], AdjustCityImportList);
    return AdjustCityImportList;
}(Vue));
export default AdjustCityImportList;
