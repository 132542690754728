import { render, staticRenderFns } from "./adjust-city-import-list.vue?vue&type=template&id=4aa70b52&scoped=true"
import script from "./adjust-city-import-list.vue?vue&type=script&lang=ts"
export * from "./adjust-city-import-list.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4aa70b52",
  null
  
)

export default component.exports