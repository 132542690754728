import { render, staticRenderFns } from "./adjust-city-import-details.vue?vue&type=template&id=f49b2032&scoped=true"
import script from "./adjust-city-import-details.vue?vue&type=script&lang=ts"
export * from "./adjust-city-import-details.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f49b2032",
  null
  
)

export default component.exports